import queryString from 'query-string'

declare global {
  interface Window {
    dataLayer: any
  }
}

// get and set function for loan amount and term getting from marketing page.
export const loanAmountAndTerm = () => {
  const url = localStorage.redirect_path_after_login
    ? localStorage.redirect_path_after_login
    : ''

  const qs = queryString.parse(url.split('?')[1])
  if (localStorage && url.includes('/borrower/onboarding')) {
    return qs
  }
}

// google tag manager login and choose laon ammount
export const productDetail: (
  appliedAmount: number,
  appliedPeriod: number
) => void = (appliedAmount, appliedPeriod) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    ecommerce: {
      detail: {
        products: [
          {
            name: 'Fixura Loan',
            price: appliedAmount,
            metric1: appliedAmount,
            metric2: appliedPeriod,
          },
        ],
      },
    },
  })
}

// google tag manager select signicat bank Id
export const addToCart: () => void = () => {
  const loanApplied = loanAmountAndTerm()

  if (loanApplied) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'addToCart',
      ecommerce: {
        currencyCode: 'EUR',
        add: {
          products: [
            {
              name: 'Fixura Loan',
              price: loanApplied.loanAmount,
              quantity: 1,
              metric1: loanApplied.loanAmount,
              metric2: loanApplied.term,
            },
          ],
        },
      },
    })
  }
}

// google tag manager mobile and email
export const checkoutStep1: () => void = () => {
  const loanApplied = loanAmountAndTerm()
  if (loanApplied) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'checkout1', //In future will be different
      userId: '{{userID#}}',
      ecommerce: {
        checkout: {
          actionField: { step: 1 },
          products: [
            {
              name: 'Fixura Loan',
              price: loanApplied.loanAmount,
              quantity: 1,
              metric1: loanApplied.loanAmount,
              metric2: loanApplied.term,
            },
          ],
        },
      },
    })
  }
}

// google tag manager mobile code check
export const checkoutStep2: () => void = () => {
  const loanApplied = loanAmountAndTerm()
  if (loanApplied) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'checkout2',
      userId: '{{userID#}}',
      ecommerce: {
        checkout: {
          actionField: { step: 2 },
          products: [
            {
              name: 'Fixura Loan',
              price: loanApplied.loanAmount,
              quantity: 1,
              metric1: loanApplied.loanAmount,
              metric2: loanApplied.term,
            },
          ],
        },
      },
    })
  }
}
