import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import StoreLoanApplicationRequest from '../../../models/Borrower/StoreLoanApplicationRequest'
import { ThunkDispatch } from 'redux-thunk'
import { connect } from 'react-redux'
import Indicator from '../../shared/Indicator'
import { State } from '../../../store'
import getActiveLoanApplicationAndStore from 'store/user/borrower/loan_application/actions/getActiveLoanApplicationAndStore'
import browserHistory from 'browserHistory'
import { getUserProfile } from 'store/user/profile/actions/getUserProfile'
import { FINNISH_NATIONALITY } from '../../../constants'

interface Props extends MappedDispatch, MappedState {}

const Marketing = (props: Props) => {
  const location = useLocation()
  const qs = queryString.parse(location.search)
  const {
    getActiveLoanApplicationAndStore,
    getUserProfile,
    isFetching,
    isUser,
    isOnFidoUser,
    nationality,
  } = props

  useEffect(() => {
    if (!isUser) {
      getUserProfile()
    }
    if (qs.loanAmount && qs.term && isUser) {
      getActiveLoanApplicationAndStoreForFinnishUser()
    }
    // eslint-disable-next-line
  }, [qs.loanAmount, qs.term, isUser, getUserProfile])

  //loan application only for Finnish users
  const getActiveLoanApplicationAndStoreForFinnishUser = () => {
    if (nationality === FINNISH_NATIONALITY && !isOnFidoUser) {
      getActiveLoanApplicationAndStore({
        amount: Number(qs.loanAmount),
        terms: Number(qs.term),
      })
    } else {
      browserHistory.push('/dashboard/investor')
    }
  }

  return <Indicator isVisible={isFetching} />
}

export interface MappedDispatch {
  getActiveLoanApplicationAndStore: (data: StoreLoanApplicationRequest) => void
  getUserProfile: () => void
}

export interface MappedState {
  isFetching: boolean
  isOnFidoUser: number
  isUser: number
  nationality?: string
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>
): MappedDispatch => ({
  getActiveLoanApplicationAndStore: (data: StoreLoanApplicationRequest) =>
    dispatch(getActiveLoanApplicationAndStore(data)),
  getUserProfile: () => dispatch(getUserProfile()),
})

const mapStateToProps = (state: State): MappedState => {
  const { data } = state.user.profile
  return {
    isFetching: state.user.borrower.loan_application.is_fetching,
    isOnFidoUser: data.is_foreigner,
    isUser: data.id,
    nationality: data.nationality,
  }
}

const enhancer = connect<MappedState, MappedDispatch, any, any>(
  mapStateToProps,
  mapDispatchToProps
)

export default enhancer(Marketing)
